import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import { useLocation } from "@reach/router"

function SEO({ description, lang, title, slug }) {
  const { site, siteImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        siteImage: file(relativePath: { eq: "meta.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              src
            }
          }
        }
      }
    `
  )

  // const location = useLocation()
  // console.log("location", location)
  // const pageUrl = location.href
  // const metaImage = location.origin + siteImage.childImageSharp.fluid.src

  const siteOrigin = "https://randopassion.guslyon.fr"
  const pageUrl = siteOrigin + slug + "/"

  const metaImage = siteOrigin + siteImage.childImageSharp.fluid.src
  const metaDescription = description || site.siteMetadata.description
  const metaTitle = `${title} | ${site.siteMetadata.title}`
  const metaAuthor = site.siteMetadata.author

  return (
    <Fragment>
      {
        pageUrl &&
        metaTitle &&
        metaDescription &&
        metaImage &&
        metaAuthor && (
          <Helmet>
            {/* START : General tags */}
            <html lang={lang} />
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />
            <link rel="canonical" href={pageUrl} />
            <link rel="image_src" href={metaImage} />
            {/* STOP : General tags */}

            {/* START : OpenGraph tags */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={pageUrl} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImage} />
            <meta property="og:image:width" content="1920" />
            <meta property="og:image:height" content="1080" />
            {/* STOP : OpenGraph tags */}

            {/* START : Twitter Card tags */}
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={metaImage} />
            <meta name="twitter:creator" content={metaAuthor} />
            {/* STOP : Twitter Card tags */}
          </Helmet>
        )}
    </Fragment>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  slug: ``,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
